body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #FFF;
  background-image: url(./img/sfondo.png);
  background-size: 100% auto;
  background-repeat: repeat-y;
}

@media (max-width: 576px) {
  body {
    font-size: 16px;
  }
}

p {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

.main {
  position: relative;
  overflow: hidden;
}

.header {
  min-height: 50px;
}

.footer {
  min-height: 50px;
}

.navbar {
  padding: 0;
  background-color: #FFF;
}

.nav-item {
  margin-right: 1rem;
}

.nav-link, .nav-link:hover {
  color: #EF78BC;
  font-size: 20px;
  font-weight: bold;
}

.list-group-item {
  color: #FFF;
  background-color: inherit;
}

.table {
  color: #FFF;
}

.item-button, .item-button:hover {
  color: #FFF;
  background-color: #EF78BC;
  border-radius: 10px;
}

.item-button-inverse, .item-button-inverse:hover {
  color: #FFF;
  background-color: transparent;
  border-radius: 10px;
}

.item-button-success, .item-button-success:hover {
  color: #FFF;
  background-color: #72C18B;
  border-radius: 10px;
}

.item-button-danger, .item-button-danger:hover {
  color: #FFF;
  background-color: #FF4D84;
  border-radius: 10px;
}

.item-button-link, .item-button-link:hover {
  color: #EF78BC;
  text-decoration: none;
}

.auth-button, .auth-button:hover {
  color: #FFF;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #EF78BC;
  border-radius: 15px;
}

.affiliation-title-outer {
  margin: 3rem 0;
  padding: 5px;
  font-size: 45px;
  font-weight: bold;
  background: linear-gradient(to right, #ED81B0 0%, #EBA0C0 25%, #F8DA81 50%, #90D8AE 75%, #F09E59 100%);
  border-radius: 15px;
}

.affiliation-title {
  margin: 0;
  padding: 0.5rem;
  background-color: #5D6EA6;
  font-size: 45px;
  font-weight: bold;
  border-radius: 10px;
}

.affiliation-subtitle {
  margin: 0 0 3rem;
  padding: 5px;
  font-size: 35px;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 1rem;
}

.affiliation-input {
  width: 100%;
  height: 100%;
}

.affiliation-select {
  width: 100%;
  height: 100%;
  color: #EF78BC;
}

.affiliation-img-profile {
  max-height: 40px;
  margin: 0 10px;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
}

.affiliation-section {
  margin: 8rem 0;
}

.affiliation-product {
  margin: 10px 0;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  cursor: pointer;
}

.affiliation-product-selected {
  margin: 10px 0;
  border: 5px solid #FFFFFF;
  border-radius: 10px;
}

#interactive.viewport canvas,
video {
  width: 100%;
  margin-top: 20px;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 0px;
  height: 0px;
}

#qrcode {
  padding: 1rem;
  background-color: #FFF;
}
